import React from "react";
import loadable from "@loadable/component";

import { Spinner } from "../../../theme";
import covidLethalityMakingOfBanner from "./covidLethalityMakingOf/banner.png";

export default {
  SaYKcOT9RFaTkUMNO7HOYg: loadable(
    () => import("./covidLethality/CovidLethality"),
    {
      fallback: <Spinner />,
    }
  ),
  "les-morts-invisibles-du-coronavirus-making-of-SaYKcOT9RFaTkUMNO7HOYg": {
    story: {
      title:
        "Comment peut-on se convaincre que la différence de mortalité entre 2019 et 2020 est réellement imputable au Covid-19 ?",
      rich_title:
        "Comment peut-on se convaincre que la différence de mortalité entre 2019 et 2020 est réellement imputable au Covid-19 ?",
      thumb_hero_url: covidLethalityMakingOfBanner,
      headline_or_extract_medium:
        "Vous avez été nombreux à lire et partager notre analyse de la surmortalité dans le cadre du Covid-19. Nous avons donc décidé de publier, pour les curieux, un complément sur notre démarche.",
      headline:
        "Vous avez été nombreux à lire et partager notre analyse de la surmortalité dans le cadre du Covid-19. Nous avons donc décidé de publier, pour les curieux, un complément sur notre démarche.",
      published_at: "2020-04-07T20:00:00",
      story_tags: [
        {
          slug: "coronavirus-6pgnwQq4SzKkLGBFy-d1zg",
          label: "Coronavirus",
        },
        {
          slug: "dataviz-883AprGcQ46h2y4NNl1K1Q",
          label: "Dataviz",
        },
        {
          slug: "sante-Z40cdLhhQiCD13KFWZFHbQ",
          label: "Santé",
        },
      ],
      authors: [
        {
          slug: "lucas-gautheron-9DAnWoo5Tlav1trWgg_Qlw",
          screenname: "Lucas Gautheron",
          description: null,
          thumb_author_story_url:
            "https://static.lemediatv.fr/authors/thumb_author_story-lucas-gautheron-9DAnWoo5Tlav1trWgg_Qlw.jpg",
        },
      ],
      topics: [],
    },
    StaticContent: loadable(
      () => import("./covidLethalityMakingOf/CovidLethalityMakingOf"),
      {
        fallback: <Spinner />,
      }
    ),
  },
};
