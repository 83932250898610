import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useDimensions from "react-use-dimensions";
import Img from "react-image";

import {
  formatDate,
  getStoryLink,
  LayoutEffectSSR,
  parseHTML,
} from "../../utilities";
import { colors, FadeInPage } from "../../theme";
import Tags from "../Tags";
import ReadMore from "../ReadMore";
import btnReadmore from "../../assets/btn-readmore-red.svg";
import placeholderImg from "../../assets/placeholder-story-item.png";

const Container = styled(FadeInPage)`
  padding-bottom: ${(props) => `${props.paddingBottom}px`};
  position: relative;
  width: 268px;

  @media (max-width: 767px) {
    width: 100%;
  }

  time {
    font-size: 1.3rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
  }

  p {
    line-height: 2.4rem;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`;

const Image = styled(Link)`
  display: block;
  margin-bottom: 33px;
  position: relative;

  :before {
    background: rgba(16, 0, 43, 0.45);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 100%;
  }

  :after {
    background: url(${btnReadmore}) white no-repeat center;
    border-radius: 50%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    content: "";
    height: 56px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.3s;
    width: 56px;
  }

  :hover {
    :before {
      opacity: 1;
    }

    :after {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  img {
    box-shadow: 8px 12px 14px rgba(46, 68, 82, 0.14);
    display: block;
    width: 100%;
  }
`;

const Title = styled.h2`
  color: black;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.2rem;
  margin: 9px 0 21px;
  transition: 0.3s;

  :hover {
    color: ${colors.primaryColor};
  }
`;

const Bottom = styled.div`
  bottom: 0;
  ${(props) => (props.isPositionAbsolute ? "position: absolute" : "")}
`;

function StoryItem({ story }) {
  const [bottomRef, { height }] = useDimensions();

  return (
    <Container as="article" paddingBottom={height}>
      <Image to={getStoryLink(story.canonical_url)}>
        <Img
          src={story.thumb_story_list_url}
          alt={story.title}
          loader={<img src={placeholderImg} alt={story.title} />}
          unloader={<img src={placeholderImg} alt={story.title} />}
        />
      </Image>
      <time>{formatDate(story.published_at, "d MMMM yyyy")}</time>
      <Link to={getStoryLink(story.canonical_url)}>
        <Title>{parseHTML(story.rich_title)}</Title>
      </Link>
      <p>{story.headline_or_extract_medium}</p>
      <Bottom ref={bottomRef} isPositionAbsolute={height ? true : false}>
        <Tags tags={story.story_tags} />
        <ReadMore to={getStoryLink(story.canonical_url)} />
      </Bottom>
    </Container>
  );
}

StoryItem.propTypes = {
  story: PropTypes.shape({
    thumb_story_list_url: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    rich_title: PropTypes.string.isRequired,
    headline_or_extract_medium: PropTypes.string.isRequired,
    story_tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    canonical_url: PropTypes.string.isRequired,
  }).isRequired,
};

export default (props) => (
  <LayoutEffectSSR>
    <StoryItem {...props} />
  </LayoutEffectSSR>
);
