import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { getStoryPage, getSubscription, getAccount } from "../configureStore";
import { fetchStoryPage } from "../configureStore/storyPage";
import { getSlugId, getHelmet, parseQuery } from "../utilities";
import { FromTopPage } from "../theme";
import PageLoader from "../components/loader/PageLoader";
import NotFound from "../components/NotFound";
import Error from "../components/Error";
import StoryPage from "../components/story/StoryPage";
import Contextual from "../components/Contextual";
import JoinUs from "../components/JoinUs";
import StoryCarousel from "../components/story/StoryCarousel";
import statics from "../components/story/statics";

class Story extends Component {
  static fetchInitialData({ slug, previewToken }, query) {
    return [fetchStoryPage(slug, previewToken, query["access-granted"])];
  }

  componentDidMount() {
    this.fetchContent();
  }

  componentDidUpdate() {
    this.fetchContent();
  }

  fetchContent() {
    const { storyPage, dispatch, match, location } = this.props;

    if (!storyPage.isFetching && !storyPage.error && !storyPage.isLoaded) {
      dispatch(
        fetchStoryPage(
          match.params.slug,
          match.params.previewToken,
          parseQuery(location.search)["access-granted"]
        )
      );
    }
  }

  render() {
    const { error, isLoaded, story } = this.props.storyPage;
    const StaticContent = statics[getSlugId(this.props.match.params.slug)];

    if (error) {
      if (error === "404") {
        return <NotFound />;
      }

      return <Error message={error} />;
    }

    if (isLoaded) {
      const microdata = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        isAccessibleForFree: "http://schema.org/True",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": story.canonical_url,
        },
        headline: story.title,
        image: {
          "@type": "ImageObject",
          url: story.thumb_hero_url,
          width: 1920,
          height: 960,
        },
        datePublished: story.published_at,
        dateModified: story.published_at,
        author: story.authors.map((author) => ({
          "@type": "Person",
          name: author.screenname,
          url: `${process.env.RAZZLE_HOSTNAME}/auteurs/${author.slug}`,
        })),
        publisher: {
          "@type": "Organization",
          name: "Le Média",
          logo: {
            "@type": "ImageObject",
            url: "http://ressources.lemediatv.fr/logo-microdata.png",
            width: 300,
            height: 60,
          },
          url: "https://lemediatv.fr",
        },
        description: story.headline_or_extract_medium,
        keywords: story.story_tags.map((tag) => tag.label).join(", "),
      };

      return (
        <FromTopPage>
          <Helmet>
            {getHelmet(
              story.title,
              story.headline_or_extract_medium,
              this.props.match.url,
              story.thumb_hero_url
            )}

            <script type="application/ld+json">
              {JSON.stringify(microdata)}
            </script>
          </Helmet>
          <StoryPage
            story={story}
            StaticContent={StaticContent}
            isSubscriber={
              (this.props.subscription &&
                this.props.subscription.payment_status === "ACTIVE") ||
              this.props.account.role === "WORKER" ||
              this.props.account.role === "UNLIMITED"
            }
          />
          <Contextual content={story.contextual_stories} />
          <JoinUs />
          <StoryCarousel stories={story.contextual_carousel} />
        </FromTopPage>
      );
    }

    return <PageLoader />;
  }
}

Story.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      previewToken: PropTypes.string,
    }).isRequired,
  }).isRequired,
  storyPage: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
    isLoaded: PropTypes.bool.isRequired,
    story: PropTypes.shape({
      title: PropTypes.string.isRequired,
      headline_or_extract_medium: PropTypes.string.isRequired,
      published_at: PropTypes.string.isRequired,
      canonical_url: PropTypes.string.isRequired,
      thumb_hero_url: PropTypes.string.isRequired,
      contextual_stories: PropTypes.arrayOf(PropTypes.object).isRequired,
      contextual_carousel: PropTypes.arrayOf(PropTypes.object).isRequired,
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          screenname: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      story_tags: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
  }).isRequired,
  subscription: PropTypes.shape({
    payment_status: PropTypes.string.isRequired,
  }),
  account: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }),
};

export default connect((state, { match }) => ({
  storyPage: getStoryPage(state, match.params.slug),
  subscription: getSubscription(state),
  account: getAccount(state),
}))(Story);
