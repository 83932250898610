import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Img from "react-image";

import {
  colors,
  gradients,
  MainWrapper,
  Date,
  useMinTabletLandscape,
} from "../../theme";
import { formatDate, getStoryLink, parseHTML } from "../../utilities";
import ReadMore from "../ReadMore";
import Share from "../Share";
import placeholderBigImg from "../../assets/placeholder-story-highlight-big.png";
import placeholderSmallImg from "../../assets/placeholder-story-highlight-small.png";
import bgMLetter from "../../assets/bg-mletter.svg";
import btnReadmore from "../../assets/btn-readmore-red.svg";

const Container = styled(MainWrapper)`
  background: url(${bgMLetter}) no-repeat left center;
  padding: 60px 0 108px;

  @media (max-width: 767px) {
    background: transparent;
    padding: 30px 0 70px;
  }

  @media (max-width: 320px) {
    /* share tooltip issue */
    overflow: hidden;
  }

  a {
    text-decoration: none;
  }

  > div {
    margin: 0 auto;
    width: 1174px;

    @media (max-width: 1599px) {
      width: 960px;
    }

    @media (max-width: 1023px) {
      width: 595px;
    }

    @media (max-width: 767px) {
      width: calc(100% - 52px);
    }

    > h1 {
      color: black;
      font-size: 4.8rem;
      font-weight: 100;
      letter-spacing: -0.07rem;
      margin-bottom: 44px;
      text-transform: uppercase;

      @media (max-width: 767px) {
        font-size: 3.4rem;
        margin-bottom: 30px;
      }
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
`;

const Left = styled.article`
  width: 540px;

  @media (max-width: 1599px) {
    width: 490px;
  }

  @media (max-width: 1023px) {
    width: 100%;

    img {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  > div {
    color: #616161;
    font-size: 1.3rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }

  h2 {
    color: black;
    font-size: 4.8rem;
    font-weight: 100;
    letter-spacing: -0.07rem;
    margin: 14px 0 29px;
    transition: 0.3s;

    :hover {
      color: ${colors.primaryColor};
    }

    @media (max-width: 767px) {
      font-size: 3.2rem;
    }
  }

  p:first-of-type {
    color: black;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 2.8rem;
    margin-bottom: 18px;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  p:last-of-type {
    color: ${colors.textColor};
    font-size: 2rem;
    font-weight: 400;
    line-height: 3.2rem;
    margin-bottom: 24px;

    @media (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 0 8px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
`;

const ShareContainer = styled.span`
  text-align: right;

  @media (max-width: 767px) {
    display: block;
    margin-top: 15px;
    text-align: center;
    width: 100%;
  }

  > a,
  button {
    border: 1px solid #707070;
    border-radius: 50%;
    color: ${colors.textColor};
    display: inline-block;
    font-size: 2rem;
    height: 44px;
    line-height: 44px;
    margin: 0 9px;
    text-align: center;
    transition: 0.3s;
    width: 44px;

    :hover {
      border-color: ${colors.primaryColor};
      color: ${colors.primaryColor};
    }
  }

  button {
    font-size: 2.8rem;
    font-weight: 100;
    line-height: 40px;
    text-decoration: none;
  }
`;

const Right = styled.div`
  margin-top: 17px;
  width: 580px;

  @media (max-width: 1599px) {
    width: 420px;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

  a,
  img {
    width: 100%;
  }

  > div {
    display: flex;
    margin-top: 32px;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
`;

const LittleArticle = styled.article`
  background: ${gradients.toDarkPurple("-15deg")},
    url(${(props) => props.background}) center,
    url(${placeholderSmallImg}) center;
  background-size: cover;
  height: 205px;
  padding: 21px 17px;
  position: relative;
  width: 289px;

  @media (max-width: 1599px) {
    height: 246px;
    width: 209px;
  }

  @media (max-width: 1023px) {
    width: 296px;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
    width: 100%;
  }

  :before {
    background: rgba(16, 0, 43, 0.45);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 100%;
  }

  :after {
    background: url(${btnReadmore}) white no-repeat center;
    border-radius: 50%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    content: "";
    height: 56px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.3s;
    width: 56px;
  }

  :hover {
    :before {
      opacity: 1;
    }

    :after {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  h2 {
    color: white;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3.2rem;
    margin-top: 7px;
  }
`;

function StoryHighlight({ stories }) {
  const isMinTabletLandscape = useMinTabletLandscape();

  const authors = stories[0].authors;
  let authorsNames;
  if (!authors.length) {
    Sentry.captureMessage(`No author on ${stories[0].title}`);
  } else if (authors.length === 1) {
    authorsNames = authors[0].screenname;
  } else if (authors.length === 2) {
    authorsNames = `${authors[0].screenname} et ${authors[1].screenname}`;
  } else {
    authorsNames = `${authors[0].screenname}, ${authors[1].screenname} et ${authors[2].screenname}`;
  }

  return (
    <Container as="section">
      <div>
        <h1>À la une des articles :</h1>
        <Flex>
          <Left>
            <div>
              {formatDate(stories[0].published_at, "d MMMM yyyy")} - Par{" "}
              {authorsNames}
            </div>
            <Link to={getStoryLink(stories[0].canonical_url)}>
              <h2>{parseHTML(stories[0].rich_title)}</h2>
            </Link>
            {!isMinTabletLandscape && (
              <Link
                to={getStoryLink(stories[0].canonical_url)}
                title={stories[0].title}
              >
                <Img
                  src={stories[0].thumb_caroussel_lg_url}
                  alt={stories[0].title}
                  loader={
                    <img src={placeholderBigImg} alt={stories[0].title} />
                  }
                  unloader={
                    <img src={placeholderBigImg} alt={stories[0].title} />
                  }
                />
              </Link>
            )}
            <p>{stories[0].extract_headline}</p>
            {stories[0].extract_home && <p>{stories[0].extract_home}</p>}
            <footer>
              <ReadMore to={getStoryLink(stories[0].canonical_url)} />
              <ShareContainer>
                <Share text={stories[0].title} />
              </ShareContainer>
            </footer>
          </Left>
          <Right>
            {isMinTabletLandscape && (
              <Link
                to={getStoryLink(stories[0].canonical_url)}
                title={stories[0].title}
              >
                <Img
                  src={stories[0].thumb_caroussel_lg_url}
                  alt={stories[0].title}
                  loader={
                    <img src={placeholderBigImg} alt={stories[0].title} />
                  }
                  unloader={
                    <img src={placeholderBigImg} alt={stories[0].title} />
                  }
                />
              </Link>
            )}
            <div>
              <Link
                to={getStoryLink(stories[1].canonical_url)}
                title={stories[1].title}
              >
                <LittleArticle background={stories[1].thumb_caroussel_md_url}>
                  <Date>{formatDate(stories[1].published_at)}</Date>
                  <h2>{parseHTML(stories[1].rich_title)}</h2>
                </LittleArticle>
              </Link>
              <Link
                to={getStoryLink(stories[2].canonical_url)}
                title={stories[2].title}
              >
                <LittleArticle background={stories[2].thumb_caroussel_md_url}>
                  <Date>{formatDate(stories[2].published_at)}</Date>
                  <h2>{parseHTML(stories[2].rich_title)}</h2>
                </LittleArticle>
              </Link>
            </div>
          </Right>
        </Flex>
      </div>
    </Container>
  );
}

StoryHighlight.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      published_at: PropTypes.string.isRequired,
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          screenname: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      title: PropTypes.string.isRequired,
      rich_title: PropTypes.string.isRequired,
      extract_headline: PropTypes.string.isRequired,
      extract_home: PropTypes.string,
      thumb_caroussel_lg_url: PropTypes.string.isRequired,
      thumb_caroussel_md_url: PropTypes.string.isRequired,
      canonical_url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default StoryHighlight;
