import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Img from "react-image";

import { parseHTML, getAuthorsPresentation, formatDate } from "../../utilities";
import {
  mobileMenuOffset,
  colors,
  gradients,
  GridWrapper,
  Date,
  Button,
  useMaxTabletPortrait,
} from "../../theme";
import AuthorCard from "../author/AuthorCard";
import Presentation from "../Presentation";
import Tags from "../Tags";
import Wysiwyg from "../Wysiwyg";
import Backstage from "../Backstage";
import placeholderImg from "../../assets/placeholder-video-item.png";
import btnReadmore from "../../assets/btn-readmore-red.svg";

const Banner = styled.div`
  background: black;
  height: 712px;
  position: relative;

  @media (max-width: 767px) {
    height: calc(100vh - ${mobileMenuOffset} + 44px);
    max-height: 712px;
    min-height: calc(568px - ${mobileMenuOffset} + 44px);
  }

  :after {
    content: "";
    background: url(${(props) => props.background}) center;
    background-size: cover;
    left: 0;
    height: 100%;
    opacity: 0.65;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > div {
    height: 100%;
    position: relative;
  }

  header {
    bottom: 163px;
    padding-left: 297px;
    padding-right: 99px;
    position: absolute;
    width: 100%;
    z-index: 1;

    @media (max-width: 1599px) {
      padding: 0 0 0 248px;
    }

    @media (max-width: 1023px) {
      padding: 0;
    }

    @media (max-width: 767px) {
      bottom: 50%;
      text-align: center;
      transform: translateY(50%);
    }
  }

  h1 {
    color: white;
    font-size: 4.8rem;
    font-weight: 500;
    letter-spacing: -0.12rem;
    line-height: 6rem;
    margin: 18px 0;
    text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.75);

    @media (max-width: 767px) {
      font-size: 3rem;
      line-height: 3.8rem;
    }
  }
`;

const Body = styled(GridWrapper)`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: -70px;
  padding-right: 99px;
  position: relative;

  @media (max-width: 1599px) {
    padding-right: 0;
  }

  @media (max-width: 767px) {
    margin-top: -30px;
  }
`;

const SidebarBox = styled.div`
  border-bottom: 1px solid ${colors.borderColor};
  border-top: 1px solid ${colors.borderColor};
  margin-bottom: 50px;
  padding: 25px 0 35px;
  text-align: center;
  width: 184px;

  @media (max-width: 1599px) {
    width: 164px;
  }

  h1 {
    color: black;
    font-size: 2rem;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  a {
    box-shadow: 4px 8px 10px rgba(46, 68, 82, 0.2);
    display: block;
    position: relative;

    :before,
    :after {
      background: ${gradients.toDarkPurple("-30deg")};
      background-size: cover;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    :after {
      background: rgba(16, 0, 43, 0.5);
      opacity: 0;
      transition: 0.3s;
    }

    :hover {
      :after {
        opacity: 1;
      }

      div {
        transform: translate(-28px, -28px) scale(1.25);
      }
    }
  }

  img {
    display: block;
    width: 100%;
  }

  div {
    border-radius: 50%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    height: 56px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-28px, -28px);
    transition: 0.3s;
    width: 56px;
    z-index: 1;
  }

  p {
    font-size: 1.4rem;
  }
`;

const Topics = styled(SidebarBox)`
  div {
    background: center no-repeat url(${btnReadmore}) white;
    background-size: 21px;
  }

  a {
    margin-top: 25px;
  }

  h2 {
    color: black;
    font-size: 1.7rem;
    font-weight: 500;
    margin-top: 15px;
  }
`;

const Main = styled.section`
  width: 778px;

  @media (max-width: 1599px) {
    width: 640px;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const PaywallWrapper = styled.div`
  ${(props) =>
    props.active &&
    `
      padding-bottom: 30px;
      position: relative;

      :after {
        background: linear-gradient(transparent, rgba(255,255,255, .9) 40%, white);
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
      }
  `}
`;

const Paywall = styled.div`
  background: white;
  bottom: 60px;
  color: black;
  font-size: 3rem;
  font-weight: 100;
  left: 50%;
  padding: 40px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  z-index: 1;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
    width: 100%;
  }

  strong {
    font-weight: 700;
  }

  a {
    line-height: 1.15;
  }
`;

const AuthorPresentation = styled(Presentation)`
  margin-bottom: 60px;
  width: auto;

  @media (max-width: 767px) {
    margin-bottom: 50px;
  }

  > a {
    left: 40px;
    transform: none;
  }

  > p {
    font-size: 1.7rem;
    line-height: 2.8rem;

    @media (max-width: 1599px) {
      width: 460px;
    }

    @media (max-width: 1023px) {
      width: 415px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

const Headline = styled.p`
  color: black;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 2.8rem;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    font-size: 2rem;
  }
`;

const Credits = styled.p`
  border-left: 3px solid ${colors.primaryColor};
  color: ${colors.gray};
  font-style: italic;
  padding-left: 20px;
`;

function StoryPage({ story, StaticContent, isSubscriber }) {
  const isMaxTabletPortrait = useMaxTabletPortrait();

  let author;
  const authors = story.authors;
  if (!authors.length) {
    Sentry.captureMessage(`No author on ${story.title}`);
  } else {
    const authorsPresentation = getAuthorsPresentation(authors);

    author = (
      <AuthorPresentation
        title={`Par ${authorsPresentation.names}`}
        titleLink={`/auteurs/${authors[0].slug}`}
        titleColor={colors.authorColor}
        content={authorsPresentation.description}
        shareText={story.title}
      />
    );
  }

  return (
    <article>
      <Banner background={story.thumb_hero_url}>
        <GridWrapper>
          <header>
            <Date>{formatDate(story.published_at)}</Date>
            <h1>{parseHTML(story.rich_title)}</h1>
            <Tags tags={story.story_tags} />
          </header>
        </GridWrapper>
      </Banner>
      <Body>
        {!isMaxTabletPortrait && (
          <aside>
            <AuthorCard
              authors={story.authors}
              tags={story.story_tags}
              title={story.title}
            />
            {story.topics.length > 0 && (
              <Topics>
                <h1>Dossiers</h1>
                <p>Vous pouvez retrouver cet article dans nos dossiers.</p>
                {story.topics.map((topic) => (
                  <React.Fragment key={topic.slug}>
                    <Link to={`/dossiers/${topic.slug}`}>
                      <Img
                        src={topic.thumb_hero_url}
                        alt={topic.title}
                        loader={<img src={placeholderImg} alt={topic.title} />}
                        unloader={
                          <img src={placeholderImg} alt={topic.title} />
                        }
                      />
                      <div />
                    </Link>
                    <h2>{parseHTML(topic.rich_title)}</h2>
                  </React.Fragment>
                ))}
              </Topics>
            )}
          </aside>
        )}
        <Main>
          {author}
          {story.headline && <Headline>{story.headline}</Headline>}
          <Wysiwyg>
            <PaywallWrapper
              active={
                story.subscriber_only &&
                !story.subscriber_access_code &&
                !isSubscriber
              }
            >
              {StaticContent ? <StaticContent /> : parseHTML(story.content)}
              {story.subscriber_only &&
                !story.subscriber_access_code &&
                !isSubscriber && (
                  <Paywall>
                    <p>
                      Pour lire la suite <strong>abonnez-vous</strong>
                    </p>
                    <Button as={Link} to="/soutien">
                      Je m'abonne et je soutiens la presse indépendante
                    </Button>
                  </Paywall>
                )}
            </PaywallWrapper>
            {story.cover_image_credits && (
              <Credits>{story.cover_image_credits}</Credits>
            )}
            {story.backstage && <Backstage>{story.backstage}</Backstage>}
          </Wysiwyg>
          {isMaxTabletPortrait && author}
        </Main>
      </Body>
    </article>
  );
}

StoryPage.propTypes = {
  story: PropTypes.shape({
    thumb_hero_url: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    rich_title: PropTypes.string.isRequired,
    story_tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        screenname: PropTypes.string.isRequired,
        description: PropTypes.string,
        slug: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    headline: PropTypes.string,
    content: PropTypes.string,
    subscriber_only: PropTypes.bool,
    cover_image_credits: PropTypes.string,
    backstage: PropTypes.string,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        thumb_hero_url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        rich_title: PropTypes.string.isRequired,
      })
    ).isRequired,
    subscriber_access_code: PropTypes.string,
  }).isRequired,
  StaticContent: PropTypes.object,
  isSubscriber: PropTypes.bool,
};

export default StoryPage;
