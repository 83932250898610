import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors, gradients, GridWrapper, Date } from "../../theme";
import { formatDate, getStoryLink, parseHTML } from "../../utilities";
import btnPlay from "../../assets/btn-play-white.svg";
import btnPlayHover from "../../assets/btn-play-red.svg";

const Container = styled.section`
  background: ${gradients.toDarkPurple("-165deg")},
    url(${(props) => props.background}) center, ${gradients.redToPurple()};
  background-size: cover;
  height: 800px;
  position: relative;

  @media (max-width: 1599px) {
    height: 100vh;
    max-height: 800px;
    min-height: 400px;
  }

  a {
    color: white;
    display: block;
    position: relative;
    height: 100%;

    :hover > div > div:last-of-type {
      transform: scale(1.3);

      :before {
        background: white;
      }

      :after {
        background-image: url(${btnPlayHover});
      }
    }
  }
`;

const Wrapper = styled(GridWrapper)`
  height: 100%;
  position: relative;
`;

const Video = styled.div`
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 540px;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    text-align: center;
  }

  h1 {
    font-size: 2.4rem;
    font-size: 600;
    display: inline-block;
    margin-bottom: 2px;
    text-transform: uppercase;
    vertical-align: middle;

    @media (max-width: 767px) {
      font-size: 2rem;
      display: block;
      margin-bottom: 5px;
    }
  }

  time {
    margin-left: 22px;

    @media (max-width: 767px) {
      margin: 0;
    }
  }

  h2 {
    font-size: 4.8rem;
    font-weight: 500;
    letter-spacing: -0.12rem;
    line-height: 6rem;

    @media (max-width: 767px) {
      font-size: 3.3rem;
      line-height: 4rem;
      margin-top: 25px;
    }
  }
`;

const LiveLabel = styled.div`
  background: ${colors.primaryColor};
  border-bottom-right-radius: 20px;
  display: inline-block;
  font-size: 5rem;
  margin: 20px 0;
  padding: 15px 30px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 4rem;
    margin-bottom: 0;
    padding: 10px 20px;
  }

  i {
    margin-right: 25px;
  }
`;

const PlayButton = styled.div`
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  height: 124px;
  left: calc(50% + 191px);
  overflow: hidden;
  position: absolute;
  top: 338px;
  transition: 0.3s;
  width: 124px;
  z-index: 100;

  @media (max-width: 1023px) {
    display: none;
  }

  :before {
    background: url(${(props) => props.background}) center;
    background-size: cover;
    content: "";
    display: block;
    filter: blur(10px) brightness(1.2);
    height: 800px;
    transform: translate(calc(-50% - 191px), -338px);
    transition: 0.3s;
    width: ${(props) => props.bgWidth}px;
    z-index: -1;
  }

  :after {
    background: url(${btnPlay}) center no-repeat;
    background-size: 26px;
    content: "";
    display: block;
    height: 100%;
    left: 5px;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 100%;
  }

  :hover {
    transform: scale(1.3);

    :before {
      background: white;
    }

    :after {
      background-image: url(${btnPlayHover});
    }
  }
`;

function Live({
  video: {
    thumb_hero_url,
    primary_category,
    canonical_url,
    published_at,
    rich_title,
  },
}) {
  const [playBgWidth, setPlayBgWidth] = useState(1960);

  useEffect(() => {
    if (playBgWidth !== window.innerWidth) {
      setPlayBgWidth(window.innerWidth);
    }
  }, [playBgWidth]);

  return (
    <Container background={thumb_hero_url}>
      <Link to={getStoryLink(canonical_url)}>
        <Wrapper>
          <Video>
            <header>
              <h1>{primary_category.label}</h1>
              <Date>{formatDate(published_at)}</Date>
            </header>
            <LiveLabel>
              <i className="fas fa-video" />
              En direct
            </LiveLabel>
            <h2>{parseHTML(rich_title)}</h2>
          </Video>
          <PlayButton background={thumb_hero_url} bgWidth={playBgWidth} />
        </Wrapper>
      </Link>
    </Container>
  );
}

Live.propTypes = {
  video: PropTypes.shape({
    thumb_hero_url: PropTypes.string.isRequired,
    primary_category: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    canonical_url: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    rich_title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Live;
