import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames";
import Img from "react-image";
import { Link } from "react-router-dom";

import { gradients, MainWrapper, Heading } from "../theme";
import { parseHTML } from "../utilities";
import Presentation from "./Presentation";
import btnLeft from "../assets/btn-program-carousel-left.svg";
import btnRight from "../assets/btn-program-carousel-right.svg";
import btnPlay from "../assets/btn-play-red.svg";
import placeholderImg from "../assets/placeholder-program-carousel.png";

const Main = styled.div`
  background: ${gradients.redToPurple("110deg")};
  color: white;
  height: 712px;
  padding-top: 46px;

  @media (max-width: 1599px) {
    height: 612px;
  }

  @media (max-width: 767px) {
    height: 478px;
  }

  header {
    margin-bottom: 38px;
  }

  h1 {
    color: white;

    @media (max-width: 767px) {
      font-size: 3.4rem;
    }
  }
`;

const Carousel = styled(MainWrapper)`
  position: relative;

  button {
    background: url(${btnLeft}) center;
    background-size: cover;
    height: 138px;
    position: absolute;
    top: 126px;
    width: 36px;

    @media (max-width: 1599px) {
      top: 76px;
    }

    @media (max-width: 767px) {
      height: 70px;
      top: 32px;
      width: 18px;
    }
  }

  button:first-of-type {
    left: 128px;

    @media (max-width: 1599px) {
      left: 45px;
    }

    @media (max-width: 1023px) {
      left: 58px;
    }

    @media (max-width: 767px) {
      left: 15px;
    }
  }

  button:last-of-type {
    background-image: url(${btnRight});
    right: 128px;

    @media (max-width: 1599px) {
      right: 45px;
    }

    @media (max-width: 1023px) {
      right: 58px;
    }

    @media (max-width: 767px) {
      right: 15px;
    }
  }
`;

const Slider = styled.div`
  height: 390px;
  margin: auto;
  perspective: 100px;
  width: 690px;

  @media (max-width: 1599px) {
    height: 290px;
    width: 513px;
  }

  @media (max-width: 767px) {
    height: 130px;
    width: 230px;
  }

  article {
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: 0.8s;
    transform: translateZ(-100px);
    visibility: hidden;
    width: 100%;

    &.first {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
      opacity: 1;
      transform: translateZ(0px);
      visibility: visible;
      z-index: 3;

      h2 {
        opacity: 1;
      }
    }

    &.second-next {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
      opacity: 1;
      transform: translateX(315px) translateZ(-25px) rotateY(-2deg);
      visibility: visible;
      z-index: 2;

      @media (max-width: 1599px) {
        transform: translateX(190px) translateZ(-25px) rotateY(-3deg);
      }

      @media (max-width: 1023px) {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        opacity: 0.4;
        transform: translateX(160px) translateZ(-30px) rotateY(-3deg);
      }

      @media (max-width: 767px) {
        opacity: 0.5;
        transform: translateX(92px) translateZ(-40px) rotateY(-3deg);
      }
    }

    &.second-prev {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
      opacity: 1;
      transform: translateX(-315px) translateZ(-25px) rotateY(2deg);
      visibility: visible;
      z-index: 2;

      @media (max-width: 1599px) {
        transform: translateX(-190px) translateZ(-25px) rotateY(3deg);
      }

      @media (max-width: 1023px) {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        opacity: 0.4;
        transform: translateX(-160px) translateZ(-30px) rotateY(3deg);
      }

      @media (max-width: 767px) {
        opacity: 0.5;
        transform: translateX(-92px) translateZ(-40px) rotateY(3deg);
      }
    }

    @media (min-width: 1024px) {
      &.third-next {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        opacity: 0.4;
        transform: translateX(795px) translateZ(-67px) rotateY(-2deg);
        visibility: visible;
        z-index: 1;

        @media (max-width: 1599px) {
          transform: translateX(490px) translateZ(-67px) rotateY(-3deg);
        }
      }

      &.third-prev {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        opacity: 0.4;
        transform: translateX(-795px) translateZ(-67px) rotateY(3deg);
        visibility: visible;
        z-index: 1;

        @media (max-width: 1599px) {
          transform: translateX(-490px) translateZ(-67px) rotateY(3deg);
        }
      }
    }

    img {
      height: 100%;
      width: 100%;
    }

    h2 {
      bottom: -56px;
      font-size: 2.4rem;
      font-weight: 600;
      opacity: 0;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      transition: 0.3s;
      width: 100%;
    }
  }
`;

const Image = styled(Link)`
  :before {
    background: rgba(16, 0, 43, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 100%;
  }

  :after {
    background: url(${btnPlay}) calc(50% + 2px) center no-repeat white;
    background-size: 20px;
    border-radius: 50%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    content: "";
    height: 87px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-43px, -45px) scale(0);
    transition: 0.3s;
    width: 87px;
    z-index: 1;
  }

  :hover {
    :before {
      opacity: 1;
    }

    :after {
      transform: translate(-43px, -45px) scale(1);
    }
  }
`;

const ProgramPresentation = styled(Presentation)`
  margin: -68px auto 8px;

  @media (max-width: 767px) {
    margin-top: -98px;
  }
`;

function ProgramCarousel({ programs }) {
  const [slide, setSlide] = useState(0);

  function slideTo(direction) {
    if (direction === "next") {
      setSlide(state => (state + 1) % programs.length);
    }

    if (direction === "previous") {
      setSlide(state => (state === 0 ? programs.length - 1 : state - 1));
    }
  }

  return (
    <section>
      <Main>
        <Heading>
          <h1>Nos programmes</h1>
          <p>Découvrez l'ensemble de nos émissions politiques et culturelles</p>
        </Heading>
        <Carousel>
          <Slider>
            {programs.map((program, i) => {
              const className = classNames({
                first: i === slide,
                "second-next": i === (slide + 1) % programs.length,
                "third-next": i === (slide + 2) % programs.length,
                "second-prev":
                  slide === 0 ? i === programs.length - 1 : i === slide - 1,
                "third-prev":
                  (slide === 0 && i === programs.length - 2) ||
                  (slide === 1 && i === programs.length - 1) ||
                  i === slide - 2
              });

              return (
                <article key={i} className={className}>
                  <Image to={`/emissions/${program.primary_category.slug}`}>
                    <Img
                      src={program.thumb_video_caroussel_url}
                      alt={program.primary_category.label}
                      loader={
                        <img
                          src={placeholderImg}
                          alt={program.primary_category.label}
                        />
                      }
                      unloader={
                        <img
                          src={placeholderImg}
                          alt={program.primary_category.label}
                        />
                      }
                    />
                  </Image>
                  <h2>{program.primary_category.label}</h2>
                </article>
              );
            })}
          </Slider>

          <button onClick={() => slideTo("previous")} />
          <button onClick={() => slideTo("next")} />
        </Carousel>
      </Main>
      <ProgramPresentation
        title={`Dernier épisode : ${parseHTML(programs[slide].rich_title)}`}
        titleLink={`/emissions/${programs[slide].primary_category.slug}`}
        content={programs[slide].primary_category.description}
        shareText={programs[slide].title}
      />
    </section>
  );
}

ProgramCarousel.propTypes = {
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      rich_title: PropTypes.string.isRequired,
      thumb_video_caroussel_url: PropTypes.string.isRequired,
      primary_category: PropTypes.shape({
        label: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  ).isRequired
};

export default ProgramCarousel;
