import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import addMinutes from "date-fns/addMinutes";

import { getHomePage } from "../configureStore";
import { fetchHomePage } from "../configureStore/homePage";
import { menuOffset, FadeInPage, Heading, Button } from "../theme";
import PageLoader from "../components/loader/PageLoader";
import Error from "../components/Error";
import Live from "../components/video/Live";
import FrontRow from "../components/FrontRow";
import StoryHighlight from "../components/story/StoryHighlight";
import ProgramCarousel from "../components/ProgramCarousel";
import StoryCarousel from "../components/story/StoryCarousel";
import JoinUs from "../components/JoinUs";
import ContentList from "../components/content/ContentList";

const LastVideos = styled.section`
  padding-top: 44px;
`;

const VideosButton = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

class Home extends Component {
  static fetchInitialData() {
    return [fetchHomePage()];
  }

  componentDidMount() {
    this.fetchContent();
  }

  componentDidUpdate() {
    this.fetchContent();
  }

  componentWillUnmount() {
    clearTimeout(this.cacheTimeout);
  }

  fetchContent() {
    const {
      homePage: { isFetching, error, isLoaded, lastUpdate },
      dispatch,
    } = this.props;

    if (
      (!isFetching && !error && !isLoaded) ||
      addMinutes(lastUpdate, 1) < new Date()
    ) {
      dispatch(fetchHomePage());
    } else if (!this.cacheTimeout) {
      clearTimeout(this.cacheTimeout);
      this.cacheTimeout = setTimeout(() => {
        this.cacheTimeout = undefined;
        this.fetchContent();
      }, 60000);
    }
  }

  render() {
    const {
      homePage: {
        error,
        isLoaded,
        live,
        frontRow,
        storyHighlight,
        programCarousel,
        storyCarousel,
        lastVideos,
      },
    } = this.props;

    if (error) {
      return (
        <div style={{ paddingTop: menuOffset }}>
          <Error message={error} />
        </div>
      );
    }

    if (isLoaded) {
      return (
        <FadeInPage>
          {live ? <Live video={live} /> : <FrontRow contentList={frontRow} />}

          <StoryHighlight stories={storyHighlight} />

          <ProgramCarousel
            programs={programCarousel.sort(() => Math.random() - 0.5)}
          />

          <StoryCarousel stories={storyCarousel} />

          <JoinUs />

          <LastVideos>
            <Heading>
              <h1>Les dernières émissions</h1>
              <p>Découvrez l'ensemble de nos dernières émissions</p>
            </Heading>
            <ContentList contentList={{ content: lastVideos }} />
            <VideosButton>
              <Button as={Link} to="/emissions">
                Toutes les émissions
              </Button>
            </VideosButton>
          </LastVideos>
        </FadeInPage>
      );
    }

    return <PageLoader />;
  }
}

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  homePage: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
    isLoaded: PropTypes.bool.isRequired,
    live: PropTypes.object,
    frontRow: PropTypes.arrayOf(PropTypes.object).isRequired,
    storyHighlight: PropTypes.arrayOf(PropTypes.object).isRequired,
    programCarousel: PropTypes.arrayOf(PropTypes.object).isRequired,
    storyCarousel: PropTypes.arrayOf(PropTypes.object).isRequired,
    lastVideos: PropTypes.arrayOf(PropTypes.object).isRequired,
    lastUpdate: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default connect((state) => ({
  homePage: getHomePage(state),
}))(Home);
