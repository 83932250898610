import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "../theme";

const Container = styled(Link)`
  border: 1px solid transparent;
  color: ${colors.primaryColor};
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.06rem;
  margin-top: 6px;
  padding: 9px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;

  :hover {
    border-color: ${colors.primaryColor};
    padding: 9px 25px 9px 15px;
  }

  i {
    margin-right: 1px;
  }
`;

function ReadMore({ to }) {
  return (
    <Container to={to}>
      <i className="far fa-chevron-right" /> Lire la suite
    </Container>
  );
}

ReadMore.propTypes = {
  to: PropTypes.string.isRequired
};

export default ReadMore;
