import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import { easeCubicOut } from "d3-ease";
import classNames from "classnames";

import { colors, gradients, GridWrapper, Date, useMobile } from "../theme";
import { formatDate, getStoryLink, parseHTML } from "../utilities";
import btnPlay from "../assets/btn-play-white.svg";
import btnPlayHover from "../assets/btn-play-red.svg";
import btnReadMore from "../assets/btn-readmore-white.svg";
import btnReadMoreHover from "../assets/btn-readmore-red.svg";
import btnPodcast from "../assets/btn-podcast-white.svg";
import btnPodcastHover from "../assets/btn-podcast-red.svg";

const Container = styled.section`
  background: ${gradients.redToPurple()};
  height: 800px;
  overflow: hidden;
  position: relative;

  @media (max-width: 1599px) {
    height: 100vh;
    max-height: 800px;
    min-height: 400px;
  }
`;

const Wrapper = styled(GridWrapper)`
  height: 100%;
  position: relative;
`;

const Item = styled(animated.article)`
  background: ${gradients.toDarkPurple("-165deg")},
    url(${(props) => props.background}) center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;

  @media (max-width: 767px) {
    background-position: center, calc(50% - 125px);
  }

  a {
    color: white;
    display: block;
    position: relative;
    height: 100%;

    :hover > div > div:last-of-type {
      transform: scale(1.3);

      :before {
        background: white;
      }

      :after {
        background-image: url(${(props) => {
          switch (props.scheme) {
            case "STORY":
              return btnReadMoreHover;
            case "AUDIO":
              return btnPodcastHover;
            default:
              return btnPlayHover;
          }
        }});
      }
    }
  }

  button {
    left: calc(50% + 192px);
    position: absolute;
    top: calc(50% - 52px);
  }
`;

const Content = styled.div`
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 540px;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    text-align: center;
  }

  h1 {
    font-size: 2.4rem;
    font-size: 600;
    display: inline-block;
    margin-bottom: 2px;
    margin-right: 22px;
    text-transform: uppercase;
    vertical-align: middle;

    @media (max-width: 767px) {
      font-size: 2rem;
      display: block;
      margin-bottom: 5px;
      margin-right: 0;
    }
  }

  h2 {
    font-size: 4.8rem;
    font-weight: 500;
    letter-spacing: -0.12rem;
    line-height: 6rem;

    @media (max-width: 767px) {
      font-size: 3.3rem;
      line-height: 4rem;
      margin-top: 25px;
    }
  }
`;

const PlayButton = styled.div`
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  height: 124px;
  left: calc(50% + 191px);
  overflow: hidden;
  position: absolute;
  top: 338px;
  transition: 0.3s;
  width: 124px;
  z-index: 100;

  @media (max-width: 1023px) {
    display: none;
  }

  :before {
    background: url(${(props) => props.background}) center;
    background-size: cover;
    content: "";
    display: block;
    filter: blur(10px) brightness(1.2);
    height: 800px;
    transform: translate(calc(-50% - 191px), -338px);
    transition: 0.3s;
    width: ${(props) => props.bgWidth}px;
    z-index: -1;
  }

  :after {
    background: url(${(props) => {
        switch (props.scheme) {
          case "STORY":
            return btnReadMore;
          case "AUDIO":
            return btnPodcast;
          default:
            return btnPlay;
        }
      }})
      center no-repeat;
    background-size: ${(props) => {
      switch (props.scheme) {
        case "STORY":
          return "34px";
        case "AUDIO":
          return "30px";
        default:
          return "26px";
      }
    }};
    content: "";
    display: block;
    height: 100%;
    left: 2px;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 100%;
  }

  :hover {
    transform: scale(1.3);

    :before {
      background: white;
    }

    :after {
      background-image: url(${btnPlayHover});
    }
  }
`;

const pending = keyframes`
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
`;

const NavContainer = styled(GridWrapper)`
  height: 90px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;

  @media (max-width: 767px) {
    height: auto;
    padding: 30px 0;
  }

  nav {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 788px;

    @media (max-width: 1023px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      margin: auto;

      position: static;
      width: 200px;

      button {
        background: ${colors.gray};
        border-radius: 50%;
        height: 20px;
        width: 20px;

        &.active {
          background: ${gradients.purpleToPink("to bottom right")};
        }
      }
    }
  }
`;

const NavButton = styled(animated.button)`
  background: transparent;
  border-left: 4px solid rgba(255, 255, 255, 0.35);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  height: 80px;
  letter-spacing: 0.02rem;
  line-height: 1.4rem;
  padding-left: 10px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  transition: 0.5s;
  width: 182px;

  @media (max-width: 1023px) {
    font-size: 1.1rem;
    padding-right: 10px;
  }

  :after {
    background: ${gradients.purpleToPink("to bottom")};
    content: "";
    display: block;
    height: 0px;
    left: -4px;
    position: absolute;
    bottom: 0;
    transition: 0.5s;
    width: 4px;
  }

  :hover,
  &.active {
    height: 90px;

    :after {
      height: 100%;
    }
  }

  &.pending:after {
    animation: ${pending} 6s ease-in;
    height: 100%;
  }

  > span {
    display: inline-block;
    height: 100%;
    vertical-align: top;
  }
`;

function FrontRow({ contentList }) {
  const isMobile = useMobile();
  const [slide, setSlide] = useState(0);
  const [shouldGoTo, setShouldGoTo] = useState(true);
  const transitions = useTransition(slide, (item) => item, {
    initial: { transform: "translate3d(0%,0,0)" },
    from: { transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0.1, transform: "translate3d(-40%,0,0)" },
    config: { duration: 1000, easing: easeCubicOut },
  });
  const goToTimeout = useRef(null);
  const shouldGoToTimeout = useRef(null);
  const [playBgWidth, setPlayBgWidth] = useState(1960);

  useEffect(() => {
    if (playBgWidth !== window.innerWidth) {
      setPlayBgWidth(window.innerWidth);
    }
  }, [playBgWidth]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShouldGoTo(false);
      setSlide((state) => (state + 1) % contentList.length);
      clearTimeout(shouldGoToTimeout.current);
      shouldGoToTimeout.current = setTimeout(() => setShouldGoTo(true), 1000);
    }, 6000);

    return () => {
      clearInterval(interval);
      clearTimeout(shouldGoToTimeout.current);
      clearTimeout(goToTimeout.current);
    };
  }, [contentList, slide]);

  function goTo(slide) {
    clearTimeout(goToTimeout.current);

    if (shouldGoTo) {
      setShouldGoTo(false);
      setSlide(slide);
      shouldGoToTimeout.current = setTimeout(() => setShouldGoTo(true), 1000);
    } else {
      goToTimeout.current = setTimeout(() => setSlide(slide), 1000);
    }
  }

  return (
    <Container>
      {transitions.map(({ item, props, key }) => {
        const content = contentList[item];
        return (
          <Item
            background={content.thumb_hero_url}
            key={key}
            style={props}
            scheme={content.scheme}
          >
            <Link to={getStoryLink(content.canonical_url)}>
              <Wrapper>
                <Content>
                  <header>
                    <h1>{content.primary_category.label}</h1>
                    <Date>{formatDate(content.published_at)}</Date>
                  </header>
                  <h2>{parseHTML(content.rich_title)}</h2>
                </Content>
                <PlayButton
                  background={content.thumb_hero_url}
                  bgWidth={playBgWidth}
                  scheme={content.scheme}
                />
              </Wrapper>
            </Link>
          </Item>
        );
      })}
      <NavContainer>
        {!isMobile ? (
          <nav role="navigation" aria-label="Menu du carousel">
            {contentList.map((item, i) => {
              const className = classNames({
                active: i === slide,
                pending: i === (slide + 1) % contentList.length,
              });
              return (
                <NavButton
                  key={`nav-${i}`}
                  className={className}
                  onClick={() => goTo(i)}
                >
                  <span>{parseHTML(item.rich_title)}</span>
                </NavButton>
              );
            })}
          </nav>
        ) : (
          <nav role="navigation" aria-label="Menu du carousel">
            <button
              className={slide === 0 && "active"}
              onClick={() => goTo(0)}
            />
            <button
              className={slide === 1 && "active"}
              onClick={() => goTo(1)}
            />
            <button
              className={slide === 2 && "active"}
              onClick={() => goTo(2)}
            />
            <button
              className={slide === 3 && "active"}
              onClick={() => goTo(3)}
            />
          </nav>
        )}
      </NavContainer>
    </Container>
  );
}

FrontRow.propTypes = {
  contentList: PropTypes.arrayOf(
    PropTypes.shape({
      thumb_hero_url: PropTypes.string.isRequired,
      scheme: PropTypes.oneOf(["VIDEO", "STORY", "AUDIO", "VIDEO_AUDIO"])
        .isRequired,
      primary_category: PropTypes.shape({
        label: PropTypes.string.isRequired,
      }).isRequired,
      canonical_url: PropTypes.string.isRequired,
      published_at: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      rich_title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default FrontRow;
