import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTransition, animated } from "react-spring";
import useDimensions from "react-use-dimensions";
import { useSwipeable } from "react-swipeable";

import {
  colors,
  useTabletLandscape,
  useTabletPortrait,
  useMobile,
  MainWrapper,
  Heading
} from "../../theme";
import { LayoutEffectSSR } from "../../utilities";
import StoryItem from "./StoryItem";
import bgContainer from "../../assets/bg-story-carousel.svg";
import btnArrowRight from "../../assets/btn-story-carousel-right.svg";
import btnArrowLeft from "../../assets/btn-story-carousel-left.svg";

const Container = styled(MainWrapper)`
  background: url(${bgContainer}) no-repeat right bottom 80px;
  padding: 44px 0 112px;

  @media (max-width: 1023px) {
    padding-bottom: 44px;
  }

  h1 {
    font-size: 4.8rem;
    margin-bottom: 6px;

    @media (max-width: 767px) {
      font-size: 3.4rem;
    }
  }
`;

const Carousel = styled.div`
  height: ${props => `${props.height}px`};
  left: 8px;
  margin: auto;
  position: relative;
  width: 1185px;

  @media (max-width: 1599px) {
    width: 888px;
  }

  @media (max-width: 1023px) {
    width: 593px;
  }

  @media (max-width: 767px) {
    left: 0;
    width: calc(100% - 52px);
  }
`;

const Slider = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 100%;
`;

const Slide = styled(animated.div)`
  align-items: stretch;
  display: flex;
  position: absolute;
`;

const Item = styled.div`
  padding-right: 29px;

  @media (max-width: 767px) {
    padding: 0;
  }

  article {
    height: 100%;
  }
`;

const Nav = styled.div`
  bottom: 2px;
  position: absolute;
  right: -31px;

  @media (max-width: 1599px) {
    right: -42px;
  }

  @media (max-width: 1023px) {
    right: -56px;
  }

  @media (max-width: 767px) {
    position: static;
  }
`;

const ArrowNav = styled.nav`
  position: relative;
  left: -11px;

  @media (max-width: 767px) {
    display: none;
  }

  button {
    background: url(${btnArrowLeft}) transparent center no-repeat;
    display: block;
    height: 28px;
    margin-top: 6px;
    text-indent: -9999px;
    transition: 0.3s;
    width: 28px;

    :last-of-type {
      background-image: url(${btnArrowRight});
    }

    :hover {
      transform: scale(1.75);
    }
  }
`;

const DotNav = styled.nav`
  margin-top: 31px;

  @media (max-width: 767px) {
    margin-top: 25px;
    text-align: center;
  }
`;

const Dot = styled.button`
  background: ${props => (props.active ? colors.primaryColor : "transparent")};
  border: 1px solid ${colors.primaryColor};
  border-radius: 50%;
  display: block;
  height: 10px;
  margin-top: 8px;
  transition: 0.3s;
  width: 10px;

  @media (max-width: 767px) {
    display: inline-block;
    margin: 0 6px;
  }

  :hover {
    background: ${colors.primaryColor};
    transform: scale(1.5);
  }
`;

function StoryCarousel({ stories }) {
  const isTabletLandscape = useTabletLandscape();
  const isTabletPortrait = useTabletPortrait();
  const isMobile = useMobile();
  let itemsToShow = 4;
  if (isTabletLandscape) {
    itemsToShow = 3;
  } else if (isTabletPortrait) {
    itemsToShow = 2;
  } else if (isMobile) {
    itemsToShow = 1;
  }
  const [slideRef, { height }] = useDimensions();
  const slideNbr = Math.ceil(stories.length / itemsToShow);
  const [slide, setSlide] = useState(0);
  const [direction, setDirection] = useState(null);
  const transitions = useTransition(slide, item => item, {
    from: () => {
      if (direction === "previous") {
        return { transform: "translate3d(-100%,0,0)" };
      }
      if (direction === "next") {
        return { transform: "translate3d(100%,0,0)" };
      }
      if (direction === "goTo") {
        return { opacity: 0 };
      }
    },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: () => {
      if (direction === "previous") {
        return { transform: "translate3d(100%,0,0)" };
      }
      if (direction === "next") {
        return { transform: "translate3d(-100%,0,0)" };
      }
      if (direction === "goTo") {
        return { opacity: 0 };
      }
    }
  });
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => slideTo("previous"),
    onSwipedLeft: () => slideTo("next"),
    trackMouse: true
  });

  function slideTo(newDirection) {
    setDirection(newDirection);
    setSlide(state => {
      switch (newDirection) {
        case "previous":
          return state === 0 ? slideNbr - 1 : state - 1;
        case "next":
          return (state + 1) % slideNbr;
        default:
          return state;
      }
    });
  }

  function goTo(item) {
    setDirection("goTo");
    setSlide(item);
  }

  const dots = [];
  for (let i = 0; i < slideNbr; i++) {
    dots.push(
      <Dot
        title={`Page ${i + 1}`}
        key={`dot-${i}`}
        active={i === slide}
        onClick={() => goTo(i)}
      />
    );
  }

  return (
    <Container as="section">
      <Heading>
        <h1>Les derniers articles</h1>
        <p>
          L'actualité mise en relief avec les reportages et les interviews du
          Média
        </p>
      </Heading>

      <Carousel height={height}>
        <Slider {...swipeHandlers}>
          {transitions.map(({ item, props, key }) => {
            const firstItemToShow = Math.max(item * itemsToShow, 0);
            return (
              <Slide ref={slideRef} key={key} style={props}>
                {stories
                  .slice(firstItemToShow, firstItemToShow + itemsToShow)
                  .map(story => (
                    <Item key={story.slug}>
                      <StoryItem story={story} />
                    </Item>
                  ))}
              </Slide>
            );
          })}
        </Slider>

        {stories.length > itemsToShow && (
          <Nav>
            <ArrowNav
              role="navigation"
              aria-label="Articles précédents et suivants"
            >
              <button onClick={() => slideTo("previous")}>Précédent</button>
              <button onClick={() => slideTo("next")}>Suivant</button>
            </ArrowNav>
            <DotNav
              role="navigation"
              aria-label="Pagination des derniers articles"
            >
              {dots}
            </DotNav>
          </Nav>
        )}
      </Carousel>
    </Container>
  );
}

StoryCarousel.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default props => (
  <LayoutEffectSSR>
    <StoryCarousel {...props} />
  </LayoutEffectSSR>
);
